<template>
  <b-col cols="12">
      <b-row class="d-flex align-items-center mb-2">
        <b-col sm="12" class="py-xs-0">
          <div class="d-flex align-items-center">
            <!-- <b-breadcrumb :items="page.breadCrumb" class="bg-transparent mb-0 mt-1" /> -->
            <img :src="mobileLogo" class="mobileLogo" alt="logo" >
          </div>
        </b-col>
      </b-row>
  </b-col>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'BreadCrumb',
  watch: {
    $route (to, from) {
      this.checkRoute()
    }
  },
  props: {
    mobileLogo: { type: String, default: require('../../../assets/images/12bookylogo.png') }
  },
  mounted () {
    this.currentRoute = this.navList.find(item => item.link.name === this.$route.name)
    const book = this.bookmark.find(item => item.link.name === this.$route.name)
    if (book !== undefined) {
      this.selectedBookmark = true
    }
  },
  computed: {
    ...mapGetters({
      page: 'Setting/activePage',
      navList: 'Setting/globalSearchState',
      bookmark: 'Setting/bookmarkState'
    })
  },
  data () {
    return {
      bookmarkSearch: '',
      currentRoute: {},
      selectedBookmark: false,
      datenow: ''
    }
  },
  methods: {
    ...mapActions({
      addToBookmarkState: 'Setting/addBookmarkAction',
      removeToBookmarkState: 'Setting/removeBookmarkAction'
    }),
    removeToBookmark (item) {
      this.removeToBookmarkState(item)
      this.selectedBookmark = false
    },
    addToBookmark (item) {
      this.addToBookmarkState(item)
      this.selectedBookmark = true
    },
    checkRoute () {
      this.currentRoute = this.navList.find(item => item.link.name === this.$route.name)
      const book = this.bookmark.find(item => item.link.name === this.$route.name)
      if (book !== undefined) {
        this.selectedBookmark = true
      } else {
        this.selectedBookmark = false
      }
    }
  }
}
</script>
<style>
.thePageName{
  display: block;
  margin-top: 20px;
  color: #808ed1;
  font-weight: bold;
}
</style>
